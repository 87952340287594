import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
const GridItem = props => {
  return (
    <React.Fragment>
      <Link to={props.item.node.fields.slug} itemProp="url">
        <div className="img-container" key={props.index}>
          <h2
            dangerouslySetInnerHTML={{
              __html: props.item.node.frontmatter.title,
            }}
          ></h2>

          <div className="image">
            <Img
              fluid={{
                ...props.item.node.frontmatter.featured_image.childImageSharp
                  .fluid,
                aspectRatio: 6 / 4,
              }}
              alt=""
              title=""
            />
          </div>
        </div>
      </Link>
      <style jsx>
        {`
          .img-container {
            position: relative;
            width: 100%;
            background: #eee;
            border-radius: 5px;
            border: 1px solid ghostwhite;
            overflow: hidden;
            z-index: 1;
          }

          .img-container::before {
            content: "";
            display: block;
            margin-top: 66.666%;
            z-index: 1;
          }

          .img-container .image {
            top: 0;
            display: block;
            position: absolute;
            width: 100%;
            height: auto;
            margin: 0 !important;
            border-radius: 5px;
            border: 1px solid gray;
            transition: 0.2s ease-in-out;
            z-index: 2;

            :hover {
              opacity: 0.4;
              transform: scale(1.1);
            }
          }
          .img-container::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 60%
            );
            z-index: 2;
          }

          .img-container h2 {
            text-transform: uppercase;
            font-weight: 200;
            position: absolute;
            left: 16px;
            bottom: 16px;
            z-index: 3;
            color: white;
            text-shadow: 0px 1px black;
            margin-bottom: 0;
            min-height: 2.186em;
            padding-right: 8px;
          }

          .img-container h2 strong {
            font-weight: bold;
          }
        `}
      </style>
    </React.Fragment>
  )
}

export default GridItem
